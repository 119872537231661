import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Select, 
  MenuItem,
  Grid,
  Typography,
  Button,
  Box,
  Modal,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { getUserFromLocalStorage } from "src/service/localStorage";
import { toast } from "react-toastify";
import axios from "axios";
import account from "src/_mock/account";
import WalletBankStatementInfoCard from "src/components/wallet/WalletBankStatementInfoCard";
import walletMoneyAddedImg from "../images/wallet-money-added.svg";
import PaidForCampBillImg from "../images/Order.svg";
import QrPayment from "../images/laxmikant_fedral.jpeg";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function WalletContent() {
  const [orderId, setOrderId] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [selectedValue, setSelectedValue] = useState("Option1");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [amount, setAmount] = useState(0);
  const [totalRemainingBalance, setTotalRemainingBalance] = useState(0);
  const [addedWalletStatement, setAddedWalletStatement] = useState([]);
  const [campBillStateMent, setCampBillStateMent] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("razorpay");
  const combinedData = [...addedWalletStatement, ...campBillStateMent];

  const user = getUserFromLocalStorage();
  const accessToken = user?.data?.access_token;
  const URL = process.env.REACT_APP_PROD_API;
  const affiliate_id = account.affiliate_id;
  const URL2 = process.env.REACT_APP_PROD_FILINGSOLUTIONS_API;


  const addbalanceToWallet = async () => {
    try {
      const url = `${URL}/api/wallet/add-balance`;
      const data = {
        amount: amount,
        order_id: orderId,
      };
  
      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setAmount(0);
        setOrderId("");
        setIsModalOpen(false);
        getTotalRemainingBalance();
        getladdedWalletStatement();
      }
    } catch (error) {
      console.log("Error While Adding Balance -->", error);
      toast.error("Error While Adding Balance!");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
    setPage(0);
  };

  const getTotalRemainingBalance = async () => {
    try {
      const url = `${URL}/api/wallet/total-remaining-balance`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setTotalRemainingBalance(
        Number(res?.data?.total_remaining_balance?.toFixed(2))
      );
    } catch (error) {
      console.log("Error While Getting Total Remaining Balance -->", error);
      toast.error("Error While Getting Total Remaining Balance!");
    }
  };

  const getladdedWalletStatement = async () => {
    try {
      const url = `${URL}/api/wallet/balance-statements`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setAddedWalletStatement(res?.data);
    } catch (error) {
      console.log("Error While Statements -->", error);
      toast.error("Error While Statements!");
    }
  };

  const getJobData = async () => {
    try {
      const url = `${URL2}/api/particularjobs/${affiliate_id}`;
      const res = await axios.get(url);
      setCampBillStateMent(res.data);
    } catch (error) {
      console.log("Error In getting jobs data--->", error);
      toast.error("Error In getting jobs data!");
    }
  };

  const formatLocalDate = (utcTimestamp) => {
    const utcDate = new Date(utcTimestamp);
    utcDate.setHours(utcDate.getHours() + 5);
    utcDate.setMinutes(utcDate.getMinutes() + 30);

    const istTimeString = utcDate.toLocaleString("en-In", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return istTimeString;
  };

  const createPaymentIntent = async (amount, method) => {
    try {
      const response = await axios.post(
        `${URL}/api/wallet/create-payment-intent`,
        { amount, payment_method: method },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating payment intent:", error);
      toast.error("Error creating payment intent");
    }
  };

  const handlePayment = async () => {
    if (amount < 10) {
      toast.error("Minimum amount is ₹10");
      return;
    }
  
    if (paymentMethod === "razorpay") {
      const paymentData = await createPaymentIntent(amount, "razorpay");
      if (paymentData) {
        handleRazorpayPayment(paymentData);
      }
    } else if (paymentMethod === "stripe") {
      const paymentData = await createPaymentIntent(amount, "stripe");
      if (paymentData) {
        handleStripePayment(paymentData);
      }
    } else if (paymentMethod === "directUPI") {
      addbalanceToWallet();
    }
  };

  const handleRazorpayPayment = (paymentData) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: paymentData.amount * 100,
      currency: paymentData.currency,
      name: "Affworld Technologies",
      description: "Add money to wallet",
      order_id: paymentData.order_id,
      handler: function (response) {
        // The payment is now handled by the webhook, so we don't need to verify it here
        toast.success("Payment successful. Your wallet will be updated shortly.");
        getTotalRemainingBalance();
        getladdedWalletStatement();
        setAmount(0);
        setIsModalOpen(false);
      },
      prefill: {
        name: user?.data?.name,
        email: user?.data?.email,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handleStripePayment = async (paymentData) => {
    if (!stripe || !elements) {
      console.error("Stripe has not loaded");
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(paymentData.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user?.data?.name,
          email: user?.data?.email,
        },
      },
    });

    if (error) {
      console.error("Error processing payment:", error);
      toast.error("Payment failed");
    } else if (paymentIntent.status === 'succeeded') {
      try {
        const verifyResponse = await axios.post(
          `${URL}/api/wallet/verify-payment`,
          {
            payment_method: "stripe",
            payment_id: paymentIntent.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        toast.success("Payment successful");
        getTotalRemainingBalance();
        getladdedWalletStatement();
        setAmount(0);
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error verifying payment:", error);
        toast.error("Payment verification failed");
      }
    }
  };

  useEffect(() => {
    getJobData();
    getTotalRemainingBalance();
    getladdedWalletStatement();
  }, []);

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <div>
      <Grid container>
        <Grid item md={7} xs={4}></Grid>
        <Grid item md={5} xs={8}>
          <Grid container spacing={4} bgcolor={""}>
            <Grid
              item
              xs={6}
              md={7}
              className="d-flex justify-content-center align-items-center "
            >
              <Button
                className="w-100 bg-info text-dark rounded-1"
                type="button"
                onClick={() => setIsModalOpen(true)}
              >
                Add Money
              </Button>
            </Grid>
            <Grid item xs={6} md={5} className="">
              <Box className=" ">
                <Typography variant="body2">Total Balance</Typography>
                <Typography variant="subtitle1">
                  ₹ {totalRemainingBalance}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box component={"div"} my={4} borderTop={"1px solid gray"}></Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="" sx={{ width: "400px" }}>
                <Select
                  value={selectedValue}
                  onChange={handleDropdownChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Header 1" }}
                  className=""
                  sx={{
                    height: "40px",
                    width: "150px",
                    border: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  <MenuItem value="Option1">All Transactions</MenuItem>
                  <MenuItem value="Option2">Added Balance</MenuItem>
                  <MenuItem value="Option3">Total Bill</MenuItem>
                </Select>
              </TableCell>
              <TableCell className="text-center w-25" style={{ flex: 2 }}>
                Amount
              </TableCell>
              <TableCell className="text-center w-25" style={{ flex: 2 }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(selectedValue === "Option1"
              ? combinedData
              : selectedValue === "Option2"
              ? addedWalletStatement
              : campBillStateMent
            )
              .map((item) => ({
                ...item,
                timestamp:
                  item.timestamp ||
                  (item.apiResponse && item.apiResponse[0]?.timestamp),
              }))
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                const {
                  name,
                  amount,
                  order_id,
                  timestamp,
                  apiResponse = [],
                  totalCharges,
                } = row;
                const apiOrder = apiResponse[0]?.response?.order || "";

                return (
                  <TableRow key={i}>
                    <TableCell className="text-center" style={{ flex: 1 }}>
                      <WalletBankStatementInfoCard
                        WalletTitle={
                          totalCharges
                            ? name
                              ? `Paid for ${name} Campaign Order`
                              : "Paid for Campaign Order"
                            : "Added to Wallet"
                        }
                        WalletDate={
                          !apiResponse.length
                            ? formatLocalDate(timestamp)
                            : formatLocalDate(apiResponse[0].timestamp)
                        }
                        WalletOrderId={`Order id : ${order_id || apiOrder}`}
                        WalletImg={
                          totalCharges ? PaidForCampBillImg : walletMoneyAddedImg
                        }
                      />
                    </TableCell>
                    <TableCell className="text-center" style={{ flex: 1 }}>
                      {amount ? `+ ${amount}` : `- ${totalCharges.toFixed(2)}`}
                    </TableCell>
                    <TableCell align="center">
  {(() => {
    if (row.apiResponse && row.apiResponse.some(res => res.response)) {
      return "Success";
    }
    const status = row.verified;
    if (status === true || status === "true" || status === "success") {
      return "Success";
    } else if (status === "pending") {
      return "Pending";
    } else {
      return "Failed";
    }
  })()}
</TableCell>

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={
          selectedValue === "Option1"
            ? combinedData.length
            : selectedValue === "Option2"
            ? addedWalletStatement.length
            : selectedValue === "Option3"
            ? campBillStateMent.length
            : 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

<Modal
  open={isModalOpen}
  onClose={() => setIsModalOpen(false)}
  aria-labelledby="add-money-modal"
  aria-describedby="modal-to-add-money-to-wallet"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}
  >
    <Typography id="add-money-modal" variant="h6" component="h2" mb={2}>
      Add Money to Wallet
    </Typography>
    <TextField
      fullWidth
      label="Amount (₹)"
      type="number"
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
      sx={{ mb: 2 }}
    />
    <RadioGroup
      aria-label="payment-method"
      name="payment-method"
      value={paymentMethod}
      onChange={(e) => setPaymentMethod(e.target.value)}
      sx={{ mb: 2 }}
    >
      <FormControlLabel value="razorpay" control={<Radio />} label="Razorpay" />
      <FormControlLabel value="stripe" control={<Radio />} label="Stripe" />
      <FormControlLabel value="directUPI" control={<Radio />} label="Direct UPI(It may take time to process)" />
    </RadioGroup>
    {paymentMethod === "stripe" && (
      <Elements stripe={stripePromise}>
        <CardElement options={cardElementOptions} />
      </Elements>
    )}
    {paymentMethod === "directUPI" && (
      <>
        <img
          src={QrPayment}
          alt="QR Code"
          style={{ width: "100%", marginBottom: "20px" }}
        />
        <Typography variant="body2" sx={{ mb: 2 }}>
          Once you've made the payment, please enter the UTR number below.
        </Typography>
        <TextField
          fullWidth
          label="Enter UTR"
          value={orderId}
          onChange={(e) => setOrderId(e.target.value)}
          sx={{ mb: 2 }}
        />
      </>
    )}
    <Button
      fullWidth
      variant="contained"
      onClick={handlePayment}
      disabled={amount < 10}
    >
      Add ₹{amount}
    </Button>
    <Typography variant="caption" display="block" mt={1}>
      Minimum amount: ₹10
    </Typography>
  </Box>
</Modal>
    </div>
  );
}
function Wallet() {
  return (
    <Elements stripe={stripePromise}>
      <WalletContent />
    </Elements>
  );
}
export default Wallet;