import React, { useEffect, useState } from "react";
import "./UserProfile.css";
// import profile from "./profile.jpg";
// import facebook from "./facebook.png";
// import twitter from "./twitter.jpeg";
import { PersonPlus } from "react-bootstrap-icons";
import { Telephone } from "react-bootstrap-icons";
import { EnvelopeFill } from "react-bootstrap-icons";
import { GeoAltFill } from "react-bootstrap-icons";
import { Phone } from "react-bootstrap-icons";
import { Globe } from "react-bootstrap-icons";
import { BagCheckFill } from "react-bootstrap-icons";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import axios from "axios";
import { Avatar, Box, ListItemText } from "@mui/material";
import { useParams } from "react-router-dom";

function App() {
  const [userData, setUserData] = useState({});
  const URL = process.env.REACT_APP_PROD_API;
  const { id } = useParams();

  const getUserData = async () => {
    const url = `${URL}/api/affiliates/info?affiliate_id=${id}`;

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(url, config);
      setUserData(res?.data);
      console.log(res?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <div className="main-container">
        <div className="info-container" style={{ padding: "10px" }}>
          <Avatar
            src={userData?.profile_pic}
            sx={{
              margin: "20px auto",
              height: 100,
              width: 100,
            }}
          />
          <p className="name">{userData?.name}</p>
          <p className="bio">{userData?.profile}</p>
          <div className="icon-container">
            <div>
              <div className="call-contain">
                <a
                  href={`tel:${userData?.contact_number}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  <Telephone
                    style={{ marginLeft: "12px", marginTop: "12px" }}
                  />
                </a>
              </div>
              Call On
            </div>
            <div>
              <div className="call-contain">
                <a
                  href={`mailto:${userData?.email}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <EnvelopeFill
                    style={{ marginLeft: "12px", marginTop: "12px" }}
                  />
                </a>
              </div>
              Email
            </div>
            <div>
              <div className="call-contain">
                <a
                  href={userData?.location}
                  target="-blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <GeoAltFill
                    style={{ marginLeft: "12px", marginTop: "12px" }}
                  />
                </a>
              </div>
              Loction
            </div>
          </div>
        </div>

        <div className="info-contain">
          <p className="text-bio">{userData?.bio}</p>
        </div>
        <div className="social-container">
          <div>
            <p className="mobnum">
              <Phone /> &nbsp; &nbsp; &nbsp; {userData?.contact_number}
            </p>
            <p className="reltext">Mobile Phone</p>
          </div>{" "}
          <hr />
          <div>
            <p className="mail">
              <EnvelopeFill /> &nbsp; &nbsp; &nbsp; {userData?.email}
            </p>
            <p className="reltext">Email</p>
          </div>
          <hr />
          <div>
            <p className="mail">
              <Globe /> &nbsp; &nbsp; &nbsp; {userData?.website_address}
            </p>
            <p className="reltext">website_address</p>
          </div>
          <hr />
          <div>
            <p className="mail">
              <GeoAltFill /> &nbsp; &nbsp; &nbsp; {userData?.affiliate_address}
            </p>
            <p className="map">Address</p>
          </div>
          <hr />
          <div>
            <p className="mobnum">
              {" "}
              <BagCheckFill /> &nbsp; &nbsp; &nbsp; {userData?.company_name}
            </p>
          </div>
        </div>
        <p className="findme">Find me on :</p>
        <div className="appcontain" style={{ padding: "20px 0" }}>
          {" "}
          <ListItemText>
            {" "}
            <LinkedInIcon sx={{ margin: "0 20px" }} />
            {userData?.linkedin_profile_link}
          </ListItemText>{" "}
          <ListItemText>
            {" "}
            <TwitterIcon sx={{ margin: "0 20px" }} />
            {userData?.twitter_handle}
          </ListItemText>{" "}
          <ListItemText>
            {" "}
            <CloudQueueIcon sx={{ margin: "0 20px" }} />
            {userData?.skype_id}
          </ListItemText>
        </div>
        {/* <button className="btn">
          {" "}
          <PersonPlus height="20px" /> Add Contact
        </button> */}
      </div>
    </div>
  );
}

export default App;
